<template>
    <v-card outlined>
        <v-card-text>
            <v-row wrap ro align="center">
                <v-col cols="12">
                    <form autocomplete="nope" @submit.prevent="postData">
                        <v-container grid-list-md fluid>
                            <v-row dense align="center">
                                <v-col cols="12" sm="12">
                                    <v-autocomplete
                                        dense
                                        v-model="servicio"
                                        v-validate="'required'"
                                        label="Producto"
                                        :items="_listaProductosOut"
                                        data-vv-as="marca"
                                        :error-messages="errors.collect('servicio')"
                                        data-vv-name="servicio"
                                        required
                                        item-text="rename"
                                        item-value="id"
                                        return-object
                                    >
                                        <template v-slot:item="data">
                                            <v-chip small color="secondaryTheme" class="caption px-2" dark
                                                >{{ data.item.marca ? data.item.marca : data.item.category }}-{{ data.item.internal_id }}</v-chip
                                            >
                                            <v-chip v-if="data.item.internal_id" small color="green" class="caption px-2" dark>{{
                                                data.item.internal_id
                                            }}</v-chip>
                                            <span class="caption"
                                                >{{ data.item.rename }} |
                                                {{ isformatNumber(data.item, 'price') }}
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field dense v-model="servicio.unidades" label="Unidades" @keypress="isNumber" />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field dense v-model="servicio.comision" label="Comisión POS (+)" />
                                </v-col>
                                <v-col v-if="user.idBussines!=1"  cols="12" sm="3">
                                    <v-text-field dense v-model="servicio.descuentos" label="Descuentos (-)" />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field dense v-model="itemTotalProduct.subTotal" filled rounded label="Sub total" readonly />
                                </v-col>
                                <v-col cols="12" sm="3" class="text-center">
                                    <v-btn block color="secondaryTheme" dark type="submit">agregar</v-btn>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                        </v-container>
                    </form>
                </v-col>
                <v-col cols="12" class="grey lighten-5">
                    <v-data-table multi-sort :headers="headers" :items="_productos" class="elevation-1" :items-per-page="20" item-key="timestamp">
                        <template v-slot:[`item.action`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="green" @click="upItem(item)">mdi-swap-vertical</v-icon>
                                </template>
                                <span>SUBIR</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" v-bind="attrs" v-on="on" small @click="deleteItem(item)">mdi-delete</v-icon>
                                </template>
                                <span>ELIMINAR</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
import { formatNumber } from '@/utils/genericUtils'
export default {
    filters: {},
    props: ['lista', 'service'],
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            servicio: DefaultForm.formSelectServicio(),
            listaServicios: [],
            headers: [
                { text: 'Actions', value: 'action', sortable: false },
                { text: 'Descripción', value: 'description' },
                { text: 'Codigo', value: 'internal_id' },
                { text: 'Marca', value: 'marca' },
                { text: 'Unidades', value: 'unidades' },
                { text: 'Moneda', value: 'tipoMoneda' },
                { text: 'Precio', value: 'priceOrigen' },
                { text: 'Comision', value: 'comision' },
                { text: 'Descuento', value: 'descuentos' },
                { text: 'SubTotal', value: 'subTotal' }
            ]
        }
    },
    watch: {
        productos: {
            deep: true,
            handler(newList) {
                if (newList.length === 0) {
                    this.listaServicios = []
                } else {
                    this.listaServicios = newList
                }
            }
        }
    },
    computed: {
        ...mapState('utilities', ['tipoDocumento', 'tipoComprobante', 'tipoBancoDestino']),

        idTipoMoneda() {
            return this.servicio.idTipoMoneda || 1
        },
        ...mapState('ventas', ['datos', 'modalReserva', 'productos']),
        ...mapState('category', ['genericUtils']),
        ...mapState('productos', ['listaProductos']),
        ...mapState('users', ['user']),
        idProducto() {
            return this.servicio.id
        },
        _productos() {
            let _listaPro = []
            try {
                //console.log('this.productos', this.productos)
                if (this.productos && this.productos.length > 0) {
                    //console.log('here')
                    this.productos.map((x) => {
                        _listaPro.push({
                            ...x,
                            tipoMoneda: x.idTipoMoneda === 1 ? 'S/' : '$',
                            _subTotal: x.subTotal || x.subTotal.toFixed(2)
                        })
                    })
                }
            } catch (error) {
                //console.log('err', error)
            }
            return _listaPro
        },
        itemTotalProduct() {
            let val = {
                price: 0,
                subTotal: 0
            }
            const { descuentos, comision, price, unidades } = this.servicio
            if (this.servicio) {
                val.subTotal = (
                    this._parseFloat(price) * this._parseFloat(unidades) +
                    this._parseFloat(comision) * this._parseFloat(unidades) -
                    this._parseFloat(descuentos)
                ).toFixed(2)
                val.price = this._parseFloat(price) + this._parseFloat(comision)
            }
            return {
                price: val.price,
                subTotal: val.subTotal
            }
        },
        _listaProductosOut() {
            let listaProductos = []
            if (this.listaProductos && this.genericUtils) {
                this.listaProductos.map((v) => {
                    const category = this.genericUtils.find((x) => x.id === v.id_category)
                    listaProductos.push({
                        ...v,
                        category: category.name,
                        comision: 0,
                        descuentos: 0,
                        unidades: 1,
                        rename: `${v.description} -|- ${v.marca} -|- ${v.internal_id}`
                    })
                })
            }
            return listaProductos
        }
    },

    methods: {
        isformatNumber(item, name) {
            const value = item[name]
            return formatNumber(value, item.idTipoMoneda)
        },
        _parseFloat(val) {
            return parseFloat(val) || 0
        },
        deleteItem(item) {
            // //console.log(item);
            let lista = this.productos
            lista = lista.filter((v) => v.timestamp != item.timestamp)
            this.$store.commit('ventas/UPDATE_PRODUCTOS', lista)
            //this.listaServicios = lista
        },
        upItem(newItem) {
            const position = this.productos.findIndex((x) => x.timestamp == newItem.timestamp)
            //console.log('position ', position)
            const lista = Object.assign([], this.productos)
            if (position >= 1) {
                const oldItem = { ...lista[position - 1], timestamp: Date.now() }
                ////console.log('lista ', lista)
                lista.splice(position, 1, oldItem)
                ////console.log('lista 1', lista)
                lista.splice(position - 1, 1, newItem)
                ////console.log('lista ', lista)
                //colors.splice(2, 0, 'purple');

                this.$store.commit('ventas/UPDATE_PRODUCTOS', lista)
            }
        },
        postData() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    let lista = []
                    const listaServicios = []
                    const servicio = {
                        ...this.servicio,
                        priceOrigen: this.servicio.price,
                        price: this.itemTotalProduct.price,
                        subTotal: this.itemTotalProduct.subTotal,
                        timestamp: Date.now()
                    }
                    const tipoCambio = servicio.idTipoMoneda === 1 ? 1 : null
                    this.$store.commit('ventas/UPDATE_DATOS', {
                        idTipoMoneda: servicio.idTipoMoneda,
                        tipoCambio
                    })

                    listaServicios.push(servicio)
                    lista = this.productos.concat(listaServicios)
                    this.$store.commit('ventas/UPDATE_PRODUCTOS', lista)
                    this.servicio = DefaultForm.formSelectServicio()
                }
            })
        }
    }
}
</script>
<style></style>
